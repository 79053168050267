import React from 'react'
import Link from 'gatsby-link';
import FaDownload from "../img/font-awesome/light/download.svg";
import {FormattedMessage} from 'react-intl';

const {detect} = require('detect-browser');


export default class DownloadButton extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            os: "Windows",
            otherOs: "macOS",
            downloadLink: this.props.linkWindows,
            otherDownloadLink: this.props.linkMac
        };
    }

    componentDidMount()
    {
        const browser = detect();

        if (browser.os === "Mac OS" || browser.os === "iOS")
        {
            this.setState({
                os: "macOS",
                otherOs: "Windows",
                downloadLink: this.props.linkMac,
                otherDownloadLink: this.props.linkWindows
            });
        }
        else
        {
            this.setState({
                os: "Windows",
                otherOs: "macOS",
                downloadLink: this.props.linkWindows,
                otherDownloadLink: this.props.linkMac
            });
        }
    }

    render()
    {
        return (
            <div className={'my-4'}>
                <Link to={this.state.downloadLink}
                      className={'btn-blue mb-2 inline-flex flex-row items-center rounded-full leading-tight shadow-md'}
                >
                    <div className={'fill-current bg-blue-200 rounded-full my-2 ml-2'}>
                        <FaDownload className={'svg-inline--fa fa-2x m-3'}/>
                    </div>
                    <div className={'text-left py-2 pl-4 pr-8'}>
                        <span className={'text-2xl font-semibold'}>
                            <FormattedMessage id={"download.button-title"}
                                              defaultMessage="Download"/>
                            <div className={'text-sm'}>
                            <FormattedMessage id={"download.button-sub-title"}
                                              defaultMessage="for {os}"
                                              values={{os: this.state.os}}/>
                            </div>
                        </span>
                    </div>
                </Link>
                <div className={'text-base text-blue-50'}>
                    <FormattedMessage id={"download.also-available-for-pre"}
                                      defaultMessage="Also available for "
                    /> <Link to={this.state.otherDownloadLink}>{this.state.otherOs}
                </Link> <FormattedMessage id={"download.also-available-for-post"}
                                          defaultMessage=" "
                />
                </div>
            </div>
        )
            ;
    }
}

