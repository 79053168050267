import styled from "@emotion/styled";
import tw from "tailwind.macro";



const Card = styled.div`
    ${tw `rounded overflow-hidden shadow-lg`};
`;

const CardImage = styled.img`
    ${tw `w-full`};
`;

const CardBody = styled.div`
    ${tw `px-6 py-4`};
`;

const CardTitle = styled.div`
    ${tw `font-bold text-xl mb-2`};
`;

const CardText = styled.p`
    ${tw `text-grey-800 text-base`};
`;


export {
    Card, CardImage, CardBody, CardTitle, CardText
}
