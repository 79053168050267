import React from "react"
import {graphql} from "gatsby"
import Layout from '../components/layout';

import rehypeReact from "rehype-react";
import {Card, CardBody, CardImage, CardText, CardTitle} from "../components/card";
import DownloadButton from "../components/download-button";

export default function Template({
                                     data, // this prop will be injected by the GraphQL query below.
                                 }) {
    const {markdownRemark} = data; // data.markdownRemark holds our post data
    const {frontmatter, htmlAst} = markdownRemark;

    const lang = frontmatter.lang ? frontmatter.lang : "en";

    const renderAst = new rehypeReact({
        createElement: React.createElement,
        components: {
            "card": Card,
            "cardimage": CardImage,
            "cardbody": CardBody,
            "cardtitle": CardTitle,
            "cardtext": CardText,
            "DownloadButton": DownloadButton
        }
    }).Compiler;

    return (

        <Layout lang={lang}
                title={frontmatter.title}
                description={frontmatter.description}
                excludeFromIndex={frontmatter.excludeFromIndex}
                translated={frontmatter.translated}
                location={frontmatter.path}
        >

            <div className="container mx-auto container-md mt-6 mb-12">
                <h1 className={'mb-6 text-3xl md:text-4xl font-bold'}>{frontmatter.title}</h1>
                <div className={'markdown'}>
                    {renderAst(htmlAst)}
                </div>
            </div>

        </Layout>
    )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        path
        title
        lang
        description
        excludeFromIndex
        translated {
            de
            en
        }
      }
    }
    site: site {
        siteMetadata {
            title
            siteUrl
        }
    }
  }
`;
